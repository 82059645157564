exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ana-toimil-tsx": () => import("./../../../src/pages/ana-toimil.tsx" /* webpackChunkName: "component---src-pages-ana-toimil-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-sanity-legals-slug-es-current-tsx": () => import("./../../../src/pages/legal/{sanityLegals.slug__es__current}.tsx" /* webpackChunkName: "component---src-pages-legal-sanity-legals-slug-es-current-tsx" */),
  "component---src-pages-servicios-consulta-online-tsx": () => import("./../../../src/pages/servicios/consulta-online.tsx" /* webpackChunkName: "component---src-pages-servicios-consulta-online-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */)
}

